/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


// 				$('.portfolio-grid .logo .logo-wrap').matchHeight({byRow: true});
				$('.portfolio-grid .logo .logo-wrap figure').matchHeight({byRow: false});

        // insert FontAwesome to Social Navs using link name
        $( ".social-nav li" ).each(function( index ) {
          var nameaa = $( this ).find('a').text().toLowerCase();
          $( this ).find('a').html("<i class='fab fa-"+nameaa+"'></i>");
        });

        $('.slides').slick({
          dots: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          fade: true,
          cssEase: 'linear',
          rows: 0
        });

        /****************
        **  Library Tile hide/show
        *****************/
        $(".library-categories ul li a").click(function(e) {
          e.preventDefault();
          var parentID = $(this).parent().parent().parent().parent().attr('id');
          $("#" + parentID + " .library-categories ul li a").removeClass('active');
          $(e.currentTarget).addClass('active');
          $("#" + parentID + " .article-grid article").hide();
          $("#" + parentID + " .article-grid article." + e.currentTarget.id).show();
          return false;
        });


        /****************
        **  Close Alert Bar
        *****************/
        if( $('.alertbar').length ) {
          if (typeof Cookies.get('alertHider') === 'undefined') {
            $('body').addClass('alertbar-engaged');
          }
        }
        $(".alertbar .closealert").on('click', function (e) {
          e.preventDefault();
          $('body').removeClass('alertbar-engaged');
          Cookies.set('alertHider', true);
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
				$('.block-join .tiles .tile article div').matchHeight();
				$('.block-join .tiles .tile article').matchHeight();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Library
    'library': {
      init: function() {
        /****************
        **  Library Tile hide/show
        *****************/
        $(".categories ul li a").click(function(e) {
          e.preventDefault();
          $(".categories ul li a").removeClass('active');
          $(e.currentTarget).addClass('active');
          $(".block-blog article").hide();
          $(".block-blog article." + e.currentTarget.id).show();
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_post': {
      init: function() {
				$('#menu-primary-navigation .menu-podcast').removeClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'category_podcast': {
      init: function() {
				$('#menu-primary-navigation .menu-news-events').removeClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'tax_event_type': {
      init: function() {
				$('#menu-primary-navigation .menu-events').addClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'capital': {
      init: function() {
        $('.block-portfolio p.action .btn').click(function(e) {
          e.preventDefault();
          $( ".block-portfolio .company.additional").toggle("fast");
          $( ".block-portfolio  p.action .btn .less" ).toggle();
          $( ".block-portfolio  p.action .btn .more" ).toggle();
        });
        $('.block-seed p.action .btn').click(function(e) {
          e.preventDefault();
          $( ".block-seed .full-content").toggle("fast");
          $( ".block-seed  p.action .btn .less" ).toggle();
          $( ".block-seed  p.action .btn .more" ).toggle();
        });
      }
    },
    'membership': {
      init: function() {
        $('.block-portfolio p.action .btn').click(function(e) {
          e.preventDefault();
          $( ".block-portfolio .company.additional").toggle("fast");
          $( ".block-portfolio  p.action .btn .less" ).toggle();
          $( ".block-portfolio  p.action .btn .more" ).toggle();
        });
        $('img[src$=".svg"]').each(function() {
          var $img = jQuery(this);
          var imgURL = $img.attr('src');
          var attributes = $img.prop("attributes");

          $.get(imgURL, function(data) {
              // Get the SVG tag, ignore the rest
              var $svg = jQuery(data).find('svg');

              // Remove any invalid XML tags
              $svg = $svg.removeAttr('xmlns:a');

              // Loop through IMG attributes and apply on SVG
              $.each(attributes, function() {
                  $svg.attr(this.name, this.value);
              });

              // Replace IMG with SVG
              $img.replaceWith($svg);
          }, 'xml');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
